import {Route, Routes, useParams} from "react-router-dom";
import TeamList from "./TeamList";
import IndividualResponse from "./IndividualResponse";
import {useEffect, useState} from "react";
import SkillpacaApi from "../../services/SkillpacaApi";
import ResponsiveDrawer, {ListItems} from "../ResponsiveDrawer";
import FullScreenLoading from "../util/FullScreenLoading";
import CombinedResponses from "./CombinedResponses";

const skillpacaApi = SkillpacaApi()

export default function IndividualOverviewContainer() {

    const {surveyId} = useParams();

    const [isLoading, setIsLoading] = useState(true);
    const [survey, setSurvey] = useState();
    const [responses, setResponses] = useState();

    useEffect(() => {
        setIsLoading(true)
        Promise.all([skillpacaApi.getSurvey(surveyId), skillpacaApi.getResponses(surveyId)]).then(([survey, responses]) => {
            setSurvey(survey)
            responses.sort((a, b) => new Date(a.modified) - new Date(b.modified))
            setResponses(responses)
            setIsLoading(false)
        })
    }, [surveyId])

    if (isLoading) {
        return (
            <ResponsiveDrawer surveyId={surveyId} activeItem={ListItems.individual}>
                <FullScreenLoading/>
            </ResponsiveDrawer>
        )
    }

    return (
        <ResponsiveDrawer surveyId={surveyId} activeItem={ListItems.individual}>
            <Routes>
                <Route path="/combined" element={<CombinedResponses survey={survey} responses={responses} />} />
                <Route path="/:username" element={<IndividualResponse survey={survey} responses={responses}/>}/>
                <Route path="/" element={<TeamList survey={survey} responses={responses} />} />
            </Routes>
        </ResponsiveDrawer>
    )
}
