import {useNavigate, useParams} from "react-router-dom";
import {DataGrid, GridToolbarContainer, GridToolbarExport} from '@material-ui/data-grid';
import {useEffect, useState} from "react";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import Grid from "@material-ui/core/Grid";
import {Button, Checkbox, FormControlLabel, FormGroup, Typography} from "@material-ui/core";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const columns = [
    { field: 'category', headerName: 'Category', flex: 0.6 },
    { field: 'title', headerName: 'Skill', flex: 0.8 },
    { field: 'numberWithTheSkill', headerName: 'No. have the skill', type: 'number', flex: 0.6},
    { field: 'averageSelfRating', headerName: 'Avg Self Rating', type: 'number', flex: 0.6 },
    { field: 'averageJoyRating', headerName: 'Avg Joy Rating', type: 'number', flex: 0.6},
    { field: 'numberWantToLearn', headerName: 'No. Interested in Learning', type: 'number', flex: 0.6}
];

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarExport />
        </GridToolbarContainer>
    );
}


export default function SkillsExplore({survey, responses, selectedSkills}) {
    const {responseId} = useParams()
    const navigate = useNavigate()
    const [isResponseSelected, setIsResponseSelected] = useState({})
    const [selection, setSelection] = useState([])

    const [rows, setRows] = useState([])

    useEffect(() => {
        const isResponseSelected = {};
        responses.forEach(response => {
            isResponseSelected[response.id] = true
        })
        setIsResponseSelected(isResponseSelected)
        updateRows(responses, isResponseSelected, survey)

    }, [survey, responseId, responses])

    useEffect(() => {
        updateRows(responses, isResponseSelected, survey)
    }, [responses, isResponseSelected, survey])

    function updateRows(responses, isResponseSelected, survey) {
        const rows = []
        if (survey && survey.skillGroups && responses && Array.isArray(responses)) {
            Object.entries(survey.skillGroups).forEach(([skillGroupId, skillGroupSkills]) => {
                Object.entries(skillGroupSkills).forEach(([skillId, skill]) => {

                    let numberWithTheSkill = 0
                    let numberWantToLearn = 0
                    let accumulatedJoyRating = 0
                    let accumulatedSelfRating = 0
                    responses.forEach(response => {
                        if (isResponseSelected[response.id] && response.skillGroups && response.skillGroups[skillGroupId] && response.skillGroups[skillGroupId][skillId]) {
                            const responseData = response.skillGroups[skillGroupId][skillId]
                            if (responseData.isSelected) {
                                numberWithTheSkill++
                                accumulatedJoyRating += responseData.joyRating
                                accumulatedSelfRating += responseData.selfRating
                            }
                            if (responseData.isLearn) {
                                numberWantToLearn++
                            }
                        }
                    })

                    rows.push({
                        id: skillId,
                        category: skill.category,
                        title: skill.title,
                        numberWithTheSkill: numberWithTheSkill,
                        averageSelfRating: numberWithTheSkill > 0 ? accumulatedSelfRating/numberWithTheSkill : 0,
                        averageJoyRating: numberWithTheSkill > 0 ? accumulatedJoyRating/numberWithTheSkill : 0,
                        numberWantToLearn: numberWantToLearn
                    })

                })
            })
        }

        setRows(rows)
    }

    function toggleIsResponseSelected(responseId) {
        const isResponseSelectedLocal = Object.assign({}, isResponseSelected);
        isResponseSelectedLocal[responseId] = !isResponseSelectedLocal[responseId]
        setIsResponseSelected(isResponseSelectedLocal)
    }

    const checkboxes = responses.map((response) => {
        return (
            <FormControlLabel key={response.id}
                              control={
                                  <Checkbox
                                      checked={isResponseSelected[response.id] ? true : false}
                                      onChange={() => toggleIsResponseSelected(response.id)}
                                      name={response.id}
                                      color="primary"
                                  />
                              }
                              label={response.name || '*No name given*'}
            />
        )
    });

    return (
        <Grid container>
            <Grid item xs={12} style={{marginBottom: 16, marginTop: 8}}>
                <Typography variant={"h5"}><span  onClick={() => navigate(-1)} style={{cursor: 'pointer'}}><ArrowBackIcon style={{verticalAlign: 'middle'}}/>Combined</span></Typography>
            </Grid>
            <Grid item xs={10} style={{marginBottom: 16, marginTop: 8}}>
                <FormGroup row>
                    {checkboxes}
                </FormGroup>
            </Grid>
            <Grid item xs={2} style={{marginBottom: 16, marginTop: 8}}>
                <Button color="primary" variant="contained" disabled={selection.length === 0}>Explore selected skills</Button>
            </Grid>
            <Grid item xs={12}>
                <div style={{ width: '100%' }}>
                    <DataGrid autoHeight
                              rows={rows}
                              columns={columns}
                              checkboxSelection
                              components={{
                                  Toolbar: CustomToolbar,
                              }}
                              onSelectionModelChange={(newSelection) => {
                                  setSelection(newSelection.selectionModel);
                              }}
                    />
                </div>
            </Grid>
        </Grid>
    )
}
