import {useNavigate, useParams} from "react-router-dom";
import List from "@material-ui/core/List";
import {Chip, ListItem, ListItemText, Typography} from "@material-ui/core";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import SkillpacaApi from "../../services/SkillpacaApi";
import {useEffect, useState} from "react";
import ResponsiveDrawer, {ListItems} from "../ResponsiveDrawer";
import LaunchIcon from '@material-ui/icons/Launch';
import FullScreenLoading from "../util/FullScreenLoading";

const skillpacaApi = SkillpacaApi()

export default function LearningPlansList({survey}) {

    const navigate = useNavigate()
    const {surveyId} = useParams()

    const [responses, setResponses] = useState([])
    const [learningPlans, setLearningPlans] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        setIsLoading(true)
        Promise.all([skillpacaApi.getLearningPlans(surveyId), skillpacaApi.getResponses(surveyId)]).then(([learningPlans, responses]) => {
            setLearningPlans(learningPlans)
            setResponses(responses)
            setIsLoading(false)
        })
    }, [surveyId])

    function getName(userId) {
        let name = "*No name found*"
        responses.forEach(response => {
            if (response.userId === userId) {
                name = response.name
            }
        })
        return name
    }

    if (isLoading) {
        return (
            <ResponsiveDrawer surveyId={surveyId} activeItem={ListItems.learningPlans}>
                <FullScreenLoading/>
            </ResponsiveDrawer>
        )
    }

    let cardJsx = []
    if (learningPlans) {
        cardJsx = learningPlans.map((learningPlan) => {
            return (
                <ListItem key={learningPlan.id} button component="a" href={learningPlan.notionUrl} target="_blank">
                    <ListItemText primary={getName(learningPlan.userId)} />
                    <span style={{paddingLeft: 8}}><LaunchIcon/></span>
                </ListItem>
            )
        })
    }

    if (cardJsx.length === 0) {
        return (
            <ResponsiveDrawer surveyId={surveyId} activeItem={ListItems.learningPlans}>
                <Typography>There have not been any learning plans configured</Typography>
            </ResponsiveDrawer>
        )
    }

    return (
        <ResponsiveDrawer surveyId={surveyId} activeItem={ListItems.learningPlans}>
            <List component="nav" aria-label="main mailbox folders">
                {cardJsx}
            </List>
        </ResponsiveDrawer>
    )
}
