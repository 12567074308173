import { createMuiTheme } from '@material-ui/core/styles';

export const theme = createMuiTheme({
    typography: {
        fontFamily: [
            'Noto Sans JP, sans-serif',
            'Roboto',
            'Questrial, sans-serif',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    },
    palette: {
        primary: {
            main: "#5B10FF",
            // main: "#335eea",
            // main: "#7f2bef",
            // main: "#5030ff",
        },
        secondary: {
            main: "#ea2957",
        },
        background: {
            default: "#f5f5f5"
        }
    },
    custom: {
        skillItemBackground: "#EAEFFD"
    },
    overrides: {
        MuiButton: {
            containedPrimary: {
                color: 'white',
            },
        },
    }
});
