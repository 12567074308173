import {Route, Routes, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import SkillpacaApi from "../../services/SkillpacaApi";
import ResponsiveDrawer, {ListItems} from "../ResponsiveDrawer";
import FullScreenLoading from "../util/FullScreenLoading";
import SkillsTable from "./SkillsTable";
import CombinedResponses from "../individual/CombinedResponses";
import IndividualResponse from "../individual/IndividualResponse";
import TeamList from "../individual/TeamList";
import SkillsExplore from "./SkillsExplore";
import SkillsMatrix from "./SkillsMatrix";

const skillpacaApi = SkillpacaApi()

export default function SkillsOverviewContainer() {

    const {surveyId} = useParams();

    const [isLoading, setIsLoading] = useState(true);
    const [survey, setSurvey] = useState();
    const [responses, setResponses] = useState();

    useEffect(() => {
        setIsLoading(true)
        Promise.all([skillpacaApi.getSurvey(surveyId), skillpacaApi.getResponses(surveyId)]).then(([survey, responses]) => {
            setSurvey(survey)
            const selfResponses = responses.filter(response => response.username && response.status === "COMPLETED" && response.username === response.targetUsername)
            selfResponses.sort((a, b) => new Date(a.modified) - new Date(b.modified))
            setResponses(selfResponses)
            setIsLoading(false)
        })
    }, [surveyId])

    if (isLoading) {
        return (
            <ResponsiveDrawer surveyId={surveyId} activeItem={ListItems.skills}>
                <FullScreenLoading/>
            </ResponsiveDrawer>
        )
    }

    return (
        <ResponsiveDrawer surveyId={surveyId} activeItem={ListItems.skills}>
            <Routes>
                <Route path="/explore" element={<SkillsExplore survey={survey} responses={responses} />} />
                <Route path="/" element={<SkillsMatrix survey={survey} responses={responses} />} />
            </Routes>
        </ResponsiveDrawer>
    )
}
