import {Route, Routes, useLocation} from 'react-router-dom';
import NotFound from "./components/util/NotFound";
import Login from "./components/auth/Login";
import {useEffect, useState} from "react";
import AuthService from "./services/AuthService";
import FullScreenLoading from "./components/util/FullScreenLoading";
import Overview from "./components/overview/Overview";
import Home from "./components/Home";
import IndividualOverviewContainer from "./components/individual/IndividualOverviewContainer";
import LearningPlansList from "./components/learningplans/LearningPlansList";
import SkillsOverviewContainer from "./components/skills/SkillsOverviewContainer";
import LearningLibraryList from "./components/learninglibrary/LearningLibraryList";
// import MentoringContainer from "./components/mentoring/MentoringContainer";

const authService = AuthService();

function App() {

    const [isLoading, setIsLoading] = useState(true)
    const [isLoggedIn, setIsLoggedIn] = useState(false)

    const location = useLocation();

    useEffect(() => {
        authService.isLoggedIn().then(isLoggedIn => {
            setIsLoggedIn(isLoggedIn)
            setIsLoading(false)

            if (isLoggedIn) {
                const decodedToken = authService.getDecodedJwt()
                window.analytics.identify(decodedToken.payload.sub, {
                    username: decodedToken.payload['cognito:username'],
                    tenant_id: decodedToken.payload['custom:tenant_id'],
                    email: decodedToken.payload['email']
                });

                window.analytics.group(decodedToken.payload['custom:tenant_id']);
            }
        }).catch(err => {
            console.error(err)
            setIsLoading(false)
            setIsLoggedIn(false)
        })
    }, [])

    useEffect(() => {
        window.analytics.page();
    }, [location]);


    if (isLoading) {
        return <FullScreenLoading/>
    }

    if (!isLoggedIn) {
        return <Login/>
    }

    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/overview/:surveyId" element={<Overview/>}/>
            <Route path="/individual/:surveyId/*" element={<IndividualOverviewContainer/>}/>
            <Route path="/learning-plans/:surveyId/*" element={<LearningPlansList/>}/>
            <Route path="/learning-library/:surveyId/*" element={<LearningLibraryList/>}/>
            <Route path="/skills/:surveyId/*" element={<SkillsOverviewContainer/>}/>
            {/*<Route path="/mentoring/:surveyId/*" element={<MentoringContainer/>}/>*/}
            <Route path="/*" element={<NotFound />} />
        </Routes>
    );
}

export default App;
