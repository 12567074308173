import React, {useEffect, useState} from 'react';
import { Radar } from 'react-chartjs-2';
import {getRubric} from "../../services/Util";

function SelfRatingRadarChart({reviewerResponses, response, survey}){

    // let data = {
    //     labels: [],
    //     datasets: [
    //         {
    //             label: 'Self Rating',
    //             data: [],
    //             backgroundColor: 'rgba(91,16,255,0.2)',
    //             borderColor: 'rgb(91,16,255)',
    //             borderWidth: 1,
    //         },
    //     ],
    // };

    const [chartData, setChartData] = useState({
        labels: [],
        datasets: [
            {
                label: 'Self Rating',
                data: [],
                backgroundColor: 'rgba(91,16,255,0.2)',
                borderColor: 'rgb(91,16,255)',
                borderWidth: 1,
            },
        ],
    })

    useEffect(() => {

        const chartDataLocal = Object.assign({}, chartData)

        let scores = {}
        let categoryCounts = {}
        Object.entries(response.skillGroups).forEach(([skillGroupId, skillGroupSkills]) => {
            Object.entries(skillGroupSkills).forEach(([skillId, skill]) => {
                if (scores[skill.category] === undefined) {
                    scores[skill.category] = skill.selfRating ? skill.selfRating : 0
                    categoryCounts[skill.category] = 1
                } else {
                    scores[skill.category] += skill.selfRating ? skill.selfRating : 0
                    categoryCounts[skill.category] += 1
                }
            })
        })

        Object.entries(categoryCounts).forEach(([category, count]) => {
          scores[category] = scores[category] / count
        })
        chartDataLocal.labels = Object.keys(scores)
        chartDataLocal.datasets[0].data = Object.values(scores)


        //Add the reviewers scores

        if (reviewerResponses && Array.isArray(reviewerResponses) && reviewerResponses.length) {

            // reviewerResponses.forEach(response => {
            //
            //     // console.log(reviewerResponses)
            //
            //     let scores = {}
            //     let categoryCounts = {}
            //
            //     Object.entries(response.skillGroups).forEach(([skillGroupId, skillGroupSkills]) => {
            //         Object.entries(skillGroupSkills).forEach(([skillId, skill]) => {
            //             console.log(skill.category)
            //             if (scores[skill.category] === undefined) {
            //                 scores[skill.category] = skill.selfRating ? skill.selfRating : 0
            //                 categoryCounts[skill.category] = 1
            //             } else {
            //                 scores[skill.category] += skill.selfRating ? skill.selfRating : 0
            //                 categoryCounts[skill.category] += 1
            //             }
            //         })
            //     })
            //
            //     // console.log("scores")
            //     // console.log(scores)
            //
            //     Object.entries(categoryCounts).forEach(([category, count]) => {
            //         scores[category] = scores[category] / count
            //     })
            //     chartDataLocal.labels = Object.keys(scores)
            //     chartDataLocal.datasets.push({
            //         data: Object.values(scores),
            //         label: "Your rating",
            //         backgroundColor: 'rgba(255,120,16,0.2)',
            //         borderColor: 'rgb(255,112,16)',
            //         borderWidth: 1
            //     })
            // })

            const latestReviewerResponse = reviewerResponses[(reviewerResponses.length - 1)]

            let scores = {}
            let categoryCounts = {}

            Object.entries(latestReviewerResponse.skillGroups).forEach(([skillGroupId, skillGroupSkills]) => {
                Object.entries(skillGroupSkills).forEach(([skillId, skill]) => {
                    console.log(skill.category)
                    if (scores[skill.category] === undefined) {
                        scores[skill.category] = skill.selfRating ? skill.selfRating : 0
                        categoryCounts[skill.category] = 1
                    } else {
                        scores[skill.category] += skill.selfRating ? skill.selfRating : 0
                        categoryCounts[skill.category] += 1
                    }
                })
            })

            // console.log("scores")
            // console.log(scores)

            Object.entries(categoryCounts).forEach(([category, count]) => {
                scores[category] = scores[category] / count
            })
            chartDataLocal.labels = Object.keys(scores)
            chartDataLocal.datasets.push({
                data: Object.values(scores),
                label: "Your rating",
                backgroundColor: 'rgba(255,120,16,0.2)',
                borderColor: 'rgb(255,112,16)',
                borderWidth: 1
            })

        }


        setChartData(chartDataLocal)

    }, [response])

    // useEffect(() => {
    //
    //     console.log('reviewerResponses CHART')
    //     console.log(reviewerResponses)
    //
    //     const chartDataLocal = Object.assign({}, chartData)
    //
    //     console.log('chartDataLocal Before')
    //     console.log(chartDataLocal)
    //
    //
    //     console.log('chartDataLocal After')
    //     console.log(chartDataLocal)
    //
    //     setChartData({...chartData, ...chartDataLocal})
    //
    // }, [reviewerResponses])

    const options = {
        scale: {
            ticks: { beginAtZero: true},
            r: {
                angleLines: {
                    display: false
                },
                suggestedMin: 0,
                suggestedMax: getRubric(survey).length,
            }
        },
        plugins: {
            title: {
                display: true,
                text: 'Skill level',
            },
            legend: {
                display: true
            }
        },
        aspectRatio: 1.2
    };

    return (
        <React.Fragment>
            <Radar data={chartData} options={options} />
        </React.Fragment>
    )
}

export default SelfRatingRadarChart;
