import axios from "axios";
import AuthService from "./AuthService";
import {prepareResponses} from "./ResponseService";

const BASE_URL = 'https://nc0a678c29.execute-api.us-east-1.amazonaws.com/'
const authService = AuthService();

const SkillpacaApi = () => {
    const getResponses = (surveyId) => {
        return axios.get(BASE_URL + 'survey/' + surveyId + '/responses', {
            headers: {
                authorization: 'Bearer ' + authService.getJwt()
            }
        }).then(response => {
            console.log('Responses', response)
            return prepareResponses(response.data)
        })
    }

    const deleteResponse = (responseId) => {
        return axios.delete(BASE_URL + 'response/' + responseId, {
            headers: {
                authorization: 'Bearer ' + authService.getJwt()
            }
        }).then(response => {
            console.log('Delete Response', response)
            return response.data
        })
    }

    const getSurveys = () => {
        return axios.get(BASE_URL + 'survey', {
            headers: {
                authorization: 'Bearer ' + authService.getJwt()
            }
        }).then(response => {
            console.log('Surveys', response)
            return response.data
        })
    }

    const getSurvey = (surveyId) => {
        return axios.get(BASE_URL + 'survey/' + surveyId, {
            headers: {
                authorization: 'Bearer ' + authService.getJwt()
            }
        }).then(response => {
            console.log('Survey', response)
            return response.data
        })
    }

    const getLearningPlans = (surveyId) => {
        return axios.get(BASE_URL + 'survey/' + surveyId + '/learning-plans', {
            headers: {
                authorization: 'Bearer ' + authService.getJwt()
            }
        }).then(response => {
            console.log('Learning plans', response)
            return response.data
        })
    }

    const getLearningLibraryItems = (surveyId) => {
        return axios.get(BASE_URL + 'survey/' + surveyId + '/learning-library-items', {
            headers: {
                authorization: 'Bearer ' + authService.getJwt()
            }
        }).then(response => {
            console.log('Learning library items', response)
            return response.data
        })
    }

    const insertLearningLibraryItem = (surveyId, learningLibraryItem) => {
        return axios.put(BASE_URL + 'survey/' + surveyId + '/learning-library-items', learningLibraryItem, {
            headers: {
                authorization: 'Bearer ' + authService.getJwt()
            }
        }).then(response => {
            console.log('Learning library item insert', response)
            return response.data
        })
    }

    const deleteLearningLibraryItem = (learningLibraryItemId) => {
        return axios.delete(BASE_URL + 'learning-library-item/' + learningLibraryItemId, {
            headers: {
                authorization: 'Bearer ' + authService.getJwt()
            }
        }).then(response => {
            console.log('Learning library item vote', response)
            return response.data
        })
    }

    const voteLearningLibraryItem = (learningLibraryItemId, vote) => {
        return axios.put(BASE_URL + 'learning-library-item/' + learningLibraryItemId + '/vote', vote, {
            headers: {
                authorization: 'Bearer ' + authService.getJwt()
            }
        }).then(response => {
            console.log('Learning library item vote', response)
            return response.data
        })
    }

    const createResponse = (surveyId, targetUserId, targetUsername) => {
        return axios.put(BASE_URL + 'survey/' + surveyId + '/response', {targetUserId: targetUserId, targetUsername: targetUsername}, {
            headers: {
                authorization: 'Bearer ' + authService.getJwt()
            }
        }).then(response => {
            console.log('Response created', response)
            return response.data
        })
    }

    return {
        getResponses,
        deleteResponse,
        getSurveys,
        getSurvey,
        getLearningPlans,
        getLearningLibraryItems,
        insertLearningLibraryItem,
        deleteLearningLibraryItem,
        voteLearningLibraryItem,
        createResponse
    }
}

export default SkillpacaApi
