import {useNavigate, useParams} from "react-router-dom";
import {DataGrid, GridToolbarContainer, GridToolbarExport} from '@material-ui/data-grid';
import {useCallback, useEffect, useState} from "react";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import Grid from "@material-ui/core/Grid";
import {Checkbox, FormControlLabel, FormGroup, Typography} from "@material-ui/core";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SkillsSelect from "../util/SkillsSelect";

const columns = [
    { field: 'name', headerName: 'Name', flex: 0.8 },
    { field: 'category', headerName: 'Category', flex: 1 },
    { field: 'title', headerName: 'Skill', flex: 1 },
    { field: 'isSelected', headerName: 'Has the skill', type: 'boolean', flex: 0.6, renderCell: (params) => renderBoolean(params.value) },
    { field: 'selfRating', headerName: 'Self Rating', type: 'number', flex: 0.6 },
    { field: 'joyRating', headerName: 'Joy Rating', type: 'number', flex: 0.6, renderCell: (params) => renderEmoji(params.value) },
    { field: 'isLearn', headerName: 'Interested in Learning', type: 'boolean', flex: 1, renderCell: (params) => renderBoolean(params.value)}
];

function renderBoolean(value) {
    return value ? <CheckCircleIcon style={{color: 'green'}}/>: <CancelIcon style={{color: 'red'}}/>
}

function renderEmoji(value) {
    switch (value) {
        case 1:
            return <span>😫</span>
        case 2:
            return <span>🙂</span>
        case 3:
            return <span>😍</span>
        default:
            return <span></span>
    }
}

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarExport />
        </GridToolbarContainer>
    );
}


export default function CombinedResponses({survey, responses}) {
    const {responseId} = useParams()
    const navigate = useNavigate()
    const [isResponseSelected, setIsResponseSelected] = useState({})
    const [filterSkills, setFilterSkills] = useState([])

    const [rows, setRows] = useState([])

    const updateRows = useCallback((responses, isResponseSelected, survey) => {
        const rows = []
        if (survey && survey.skillGroups && responses && Array.isArray(responses)) {
            Object.entries(survey.skillGroups).forEach(([skillGroupId, skillGroupSkills]) => {
                Object.entries(skillGroupSkills).forEach(([skillId, skill]) => {

                    if (!filterSkills.length || filterSkills.includes(skillId)) {

                        responses.forEach(response => {
                            if (isResponseSelected[response.id] && response.skillGroups && response.skillGroups[skillGroupId] && response.skillGroups[skillGroupId][skillId]) {
                                const responseData = response.skillGroups[skillGroupId][skillId]
                                rows.push({
                                    name: response.name,
                                    id: skillId + '-' + response.id,
                                    category: responseData.category,
                                    title: responseData.title,
                                    isSelected: responseData.isSelected,
                                    selfRating: responseData.isSelected ? responseData.selfRating : null,
                                    joyRating: responseData.isSelected ? responseData.joyRating : null,
                                    isLearn: responseData.isLearn
                                })

                            }
                        })
                    }
                })
            })
        }

        setRows(rows)
    }, [filterSkills])

    useEffect(() => {
        const isResponseSelected = {};
        responses.forEach(response => {
            isResponseSelected[response.id] = true
        })
        setIsResponseSelected(isResponseSelected)
        updateRows(responses, isResponseSelected, survey)

    }, [updateRows, survey, responseId, responses])

    useEffect(() => {
        updateRows(responses, isResponseSelected, survey)
    }, [updateRows, responses, isResponseSelected, survey, filterSkills])


    function toggleIsResponseSelected(responseId) {
        const isResponseSelectedLocal = Object.assign({}, isResponseSelected);
        isResponseSelectedLocal[responseId] = !isResponseSelectedLocal[responseId]
        setIsResponseSelected(isResponseSelectedLocal)
    }

    function handleSkillsSelectChange(values) {
        setFilterSkills(values.map(value => value.value))
    }

    const checkboxes = responses.map((response) => {
        return (
            <FormControlLabel key={response.id}
                control={
                    <Checkbox
                        checked={isResponseSelected[response.id] ? true : false}
                        onChange={() => toggleIsResponseSelected(response.id)}
                        name={response.id}
                        color="primary"
                    />
                }
                label={response.name || '*No name given*'}
            />
        )
    });

    return (
        <Grid container>
            <Grid item xs={12} style={{marginBottom: 16, marginTop: 8}}>
                <Typography variant={"h5"}><span  onClick={() => navigate(-1)} style={{cursor: 'pointer'}}><ArrowBackIcon style={{verticalAlign: 'middle'}}/>Combined</span></Typography>
            </Grid>
            <Grid item xs={8} style={{marginBottom: 16, marginTop: 8}}>
                <FormGroup row>
                    {checkboxes}
                </FormGroup>
            </Grid>
            <Grid item xs={4} style={{marginBottom: 16, marginTop: 8}}>
                <SkillsSelect survey={survey} onChange={handleSkillsSelectChange} isMulti={true}/>
            </Grid>
            <Grid item xs={12}>
                <div style={{ width: '100%' }}>
                    <DataGrid autoHeight
                        rows={rows}
                        columns={columns}
                        checkboxSelection
                        components={{
                            Toolbar: CustomToolbar,
                        }}
                    />
                </div>
            </Grid>
        </Grid>
    )
}
