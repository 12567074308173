import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {Chip, Grid, Typography} from "@material-ui/core";
import SkillpacaApi from "../../services/SkillpacaApi";
import ResponsiveDrawer, {ListItems} from "../ResponsiveDrawer";
import LaunchIcon from '@material-ui/icons/Launch';
import FullScreenLoading from "../util/FullScreenLoading";
import AddLearningLibraryItemButton from "./AddLearningLibraryItemButton";
import Moment from 'react-moment';
import Vote from "./Vote";
import {getJsx} from "./LearningLibraryItemTypes";
import LearningLibraryItemMenu from "./LearningLibraryItemMenu";
import collaborate from "../../static/undraw_Work_chat_re_qes4.svg";

const skillpacaApi = SkillpacaApi()

export default function LearningLibraryList() {

    const {surveyId} = useParams()

    const [responses, setResponses] = useState([])
    const [survey, setSurvey] = useState([])
    const [learningLibraryItems, setLearningLibraryItems] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        setIsLoading(true)
        Promise.all([skillpacaApi.getSurvey(surveyId), skillpacaApi.getLearningLibraryItems(surveyId), skillpacaApi.getResponses(surveyId)]).then(([survey, learningLibraryItems, responses]) => {
            setSurvey(survey)
            setLearningLibraryItems(learningLibraryItems)
            setResponses(responses)
            setIsLoading(false)
        })
    }, [surveyId])

    async function deleteLearningLibraryItem(learningLibraryItemId) {
        await skillpacaApi.deleteLearningLibraryItem(learningLibraryItemId)
        await refreshLearningItems()
    }

    async function refreshLearningItems() {
        setIsLoading(true)
        const learningItems = await skillpacaApi.getLearningLibraryItems(surveyId)
        setLearningLibraryItems(learningItems)
        setIsLoading(false)
    }

    if (isLoading) {
        return (
            <ResponsiveDrawer surveyId={surveyId} activeItem={ListItems.learningLibrary}>
                <FullScreenLoading/>
            </ResponsiveDrawer>
        )
    }

    function renderSkills() {
        const skillsJsx = []
        console.log(survey)
        if (survey) {
            Object.entries(survey.skillGroups).forEach(([skillGroupId, skillGroup]) => {
                Object.entries(skillGroup).forEach(([skillId, skill]) => {
                    const learningItems = renderLearningItems(skillId)
                    if (learningItems.length) {
                        skillsJsx.push(
                            <Grid key={skillId} item xs={12}>
                                <Typography variant="h5">{skill.title}</Typography>
                                <Typography variant="subtitle2">{skill.description}</Typography>
                                <Grid container style={{paddingTop: 24, paddingRight: 24, paddingBottom: 12}}>
                                    {learningItems}
                                </Grid>
                                {/*<hr/>*/}
                            </Grid>
                        )
                    }
                })
            })
        }
        return skillsJsx
    }

    function renderLearningItems(skillId) {
        let learningItems = []
        if (learningLibraryItems) {
            const learningLibraryItemsForSkill = learningLibraryItems.filter(item => {
                return item.skills && item.skills.includes(skillId)
            })
            learningLibraryItemsForSkill.sort((a, b) => {
                return (b.upvotes.length - b.downvotes.length) - (a.upvotes.length - a.downvotes.length)
            })
            learningItems = learningLibraryItemsForSkill.map((learningLibraryItem) => {
                return (
                    <Grid key={learningLibraryItem.id} item xs={12} style={{marginBottom: 32}}>
                        <Grid container spacing={3}>
                            <Grid item xs={1} style={{textAlign: 'right'}}>
                                <Vote learningLibraryItem={learningLibraryItem}/>
                            </Grid>
                            <Grid item xs={11}>
                                {renderPrimary(learningLibraryItem)}
                                {renderSecondary(learningLibraryItem)}
                            </Grid>
                        </Grid>
                    </Grid>
                )
            })
        }
        return learningItems
    }

    function renderPrimary(learningLibraryItem) {
        return (
            <React.Fragment>
                <Grid container>
                    <Grid item xs={11}>
                        <span style={{fontSize: '16px'}}><strong>{learningLibraryItem.title}</strong></span><span style={{color: 'grey'}}> · Added by {learningLibraryItem.createdBy} <Moment fromNow>{learningLibraryItem.created}</Moment> · {getJsx(learningLibraryItem.type)}</span>
                    </Grid>
                    <Grid item xs={1}>
                        <LearningLibraryItemMenu learningLibraryItem={learningLibraryItem} deleteLearningLibraryItem={deleteLearningLibraryItem} />
                    </Grid>
                </Grid>
            </React.Fragment>
        )
    }

    function renderSecondary(learningLibraryItem) {
        return (
            <React.Fragment>
                <Typography variant={"body1"} style={{
                    color: 'black',
                    paddingTop: '8px',
                    paddingBottom: '8px'
                }}>{learningLibraryItem.description}</Typography>
                <Typography component="a" href={learningLibraryItem.url} target="_blank"><span style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: '20ch',
                    display: 'inline-block'
                }}>{learningLibraryItem.url}</span> <LaunchIcon/></Typography>
                {renderLevels(learningLibraryItem.levels)}
            </React.Fragment>
        )
    }

    function renderLevels(levels) {
        let levelsJsx = []
        if (levels && Array.isArray(levels)) {
            levelsJsx = levels.map((label) => <Chip color={"default"} variant={"default"} size={"small"} style={{marginRight:8, marginTop: 8}} label={label}/>)
        }
        return <div>{levelsJsx}</div>
    }

    const renderedSkills = renderSkills()

    if (renderedSkills.length > 0 ) {

        return (
            <ResponsiveDrawer surveyId={surveyId} activeItem={ListItems.learningLibrary} response={responses[0]}>
                <Grid container>
                    <Grid item xs={12} style={{textAlign: "right"}}>
                        <AddLearningLibraryItemButton survey={survey} onSubmitCallback={refreshLearningItems}/>
                    </Grid>
                    <Grid item xs={12}>
                        {renderedSkills}
                    </Grid>
                </Grid>
            </ResponsiveDrawer>
        )
    } else {
        return (
            <ResponsiveDrawer surveyId={surveyId} activeItem={ListItems.learningLibrary} response={responses[0]}>
                <Grid container>
                    <div style={{height: '100vh', width: '100%'}}>
                        <div style={{textAlign: 'center', marginTop: 100}}>
                            <img src={collaborate} style={{marginBottom: 24, maxWidth: '100%'}} height={300}/>
                            <Typography variant={"h5"}>Sharing is caring!</Typography>
                            <Typography variant={"subtitle1"} style={{marginBottom: 24}}>Do you know any good books, videos, courses, articles or anything else that could help your team mates?<br/>Share them here for everyone else to see.</Typography>
                            <AddLearningLibraryItemButton survey={survey} onSubmitCallback={refreshLearningItems}/>
                        </div>
                    </div>
                </Grid>
            </ResponsiveDrawer>
        )
    }
}
