import FullScreenLoading from "../util/FullScreenLoading";
import {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import AuthService from "../../services/AuthService";

const authService = AuthService()

export default function Callback() {

    const location = useLocation()

    const [isError, setIsError] = useState(false)

    useEffect(() => {

        const hash = window.location.hash.substr(1);
        const result = hash.split('&').reduce(function (res, item) {
            var parts = item.split('=');
            res[parts[0]] = parts[1];
            return res;
        }, {});
        authService.login(result.id_token).then(() => {
            window.location = '/'
        }).catch(err => {
            console.error(err)
            setIsError(true)
            //show error with retry
        })

    },[location])

    if (isError) {
        return (
            <p>There was an error when logging you in</p>
        )
    } else {
        return <FullScreenLoading/>
    }

}
