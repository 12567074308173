import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import {Checkbox, FormControlLabel, FormGroup, Typography} from "@material-ui/core";
import {getRubric} from "../../services/Util";

const width = 120
const height = 50


export default function SkillsMatrix({survey, responses}) {
    const {responseId} = useParams()
    const [isResponseSelected, setIsResponseSelected] = useState({})
    const [userResponses, setUserReponses] = useState({})

    const [rows, setRows] = useState([])
    const [columns, setColumns] = useState([])
    const [columnsMore, setColumnsMore] = useState([])
    const [rowsMore, setRowsMore] = useState([])
    const [tableNames, setTableNames] = useState([])

    const rubric = getRubric(survey)

    useEffect(() => {
        const isResponseSelected = {};
        responses.forEach(response => {
            isResponseSelected[response.id] = true
        })
        setIsResponseSelected(isResponseSelected)
        updateRows(responses, isResponseSelected, survey)

    }, [survey, responseId, responses])

    useEffect(() => {
        updateRows(responses, isResponseSelected, survey)
    }, [responses, isResponseSelected, survey])

    function updateRows(responses, isResponseSelected, survey) {
        console.log(survey)
        const rows = []
        const columns = [{ field: 'members', headerName: 'Team Members', flex: 0.8 }]

        const rowsMore = []
        const columnsMore = []

        const userResponses = {}

        responses.forEach(response => {
            if (userResponses[response.targetUsername]) {
                userResponses[response.targetUsername] = [response, ...userResponses[response.targetUsername]]
            } else {
                userResponses[response.targetUsername] = [response]
            }
        })

        setUserReponses(userResponses)

        // console.log(userResponses)

        if (survey && survey.skillGroups && responses && Array.isArray(responses)) {
            Object.entries(survey.skillGroups).forEach(([skillGroupId, skillGroupSkills], groupIndex) => {

                columnsMore[groupIndex] = [{ field: 'members', headerName: 'Team Members', flex: 0.8 }]
                tableNames[groupIndex] = survey.skillGroupsMeta[skillGroupId].title
                // columnsMore[groupIndex].push()

                Object.entries(skillGroupSkills).forEach(([skillId, skill]) => {

                    columns.push({field: skillId, headerName: skill.title})
                    columnsMore[groupIndex].push({field: skillId, headerName: skill.title})

                    Object.entries(userResponses).forEach(([username, userResponses], index) => {
                        const response = userResponses[0]
                        if (isResponseSelected[response.id] && response.status === "COMPLETED" && response.username === response.targetUsername) {

                            if (!rows[index]) {
                                rows[index] = [response.targetUserGivenName + ' ' + response.targetUserFamilyName]
                            }

                            if(!rowsMore[groupIndex]) {
                                rowsMore[groupIndex] = []
                            }

                            if(!rowsMore[groupIndex][index]) {
                                rowsMore[groupIndex][index] = [response.targetUserGivenName + ' ' + response.targetUserFamilyName]
                            }

                            if (response.skillGroups && response.skillGroups[skillGroupId] && response.skillGroups[skillGroupId][skillId]) {

                                const responseData = response.skillGroups[skillGroupId][skillId]
                                rows[index].push(responseData.selfRating)

                                rowsMore[groupIndex][index].push(responseData.selfRating)

                            } else {
                                rows[index].push(0)
                                rowsMore[groupIndex][index].push(0)
                            }
                        }
                    })
                })
            })
        }

        setColumns(columns)
        setRows(rows)

        setColumnsMore(columnsMore)
        setRowsMore(rowsMore)
    }

    function toggleIsResponseSelected(responseId) {
        const isResponseSelectedLocal = Object.assign({}, isResponseSelected);
        isResponseSelectedLocal[responseId] = !isResponseSelectedLocal[responseId]
        setIsResponseSelected(isResponseSelectedLocal)
    }

    function getBackgroundColor(level) {
        if (rubric && Array.isArray(rubric) && rubric.length > 0 && Number.isInteger(level)) {
            let value = level / rubric.length
            return 'rgba(91,16,255,' + value + ')'
        } else {
            return 'rgba(91,16,255,0)'
        }
    }

    function getTextColor(level) {
        if (rubric && Array.isArray(rubric) && rubric.length > 0 && Number.isInteger(level)) {
            if ((level - 1) < (rubric.length - 1)/2) {
                return '#000'
            } else {
                return '#fff'
            }
        }
        return '#000'
    }

    function renderRows(rows) {
        const jsx = []
        rows.forEach((row, index) => {
            jsx.push(
                <div key={index} style={{display: 'flex', height: height}}>
                    {row.map((data, i) => <div key={i} style={{width: width, textAlign: "center", backgroundColor: getBackgroundColor(data), color: getTextColor(data)}}><div style={{marginTop: 12}}>{data}</div></div>)}
                </div>
            )
        })
        return jsx
    }

    function renderTable(columns, rows, title) {
        if (rows && columns) {
            return (
                <Grid item xs={12} style={{marginBottom: 48}}>
                    <div><strong><Typography variant={"h5"} style={{marginTop: 12, paddingLeft: 8}}>{title}</Typography></strong></div>
                    <div style={{display: 'flex', marginBottom: 24, marginTop: 24}}>
                        {columns.map(column => <div key={column.field} style={{
                            // margin: 'auto',
                            textAlign: "center",
                            width: width,
                            // transform: 'rotateZ(45deg)'
                        }}><strong>{column.headerName}</strong></div>)}
                    </div>
                    {renderRows(rows)}
                </Grid>
            )
        }
    }

    const checkboxes = Object.values(userResponses).map(userResponses => {
        const response = userResponses[0]
        if (response) {
            return (
                <FormControlLabel key={response.id}
                                  control={
                                      <Checkbox
                                          checked={isResponseSelected[response.id] ? true : false}
                                          onChange={() => toggleIsResponseSelected(response.id)}
                                          name={response.id}
                                          color="primary"
                                      />
                                  }
                                  label={response.targetUserGivenName + ' ' + response.targetUserFamilyName || '*No name given*'}
                />
            )
        }
        return ''
    });

    return (
        <Grid container>
            <Grid item xs={12} style={{marginBottom: 16, marginTop: 8}}>
                <Typography variant={"h5"}><span>Skills overview</span></Typography>
            </Grid>
            <Grid item xs={10} style={{marginBottom: 16, marginTop: 8}}>
                <FormGroup row>
                    {checkboxes}
                </FormGroup>
            </Grid>
            {/*<Grid item xs={12}>*/}
            {/*    <div style={{display: 'flex', marginBottom: 24}}>*/}
            {/*            {columns.map(column => <div key={column.field} style={{margin: 'auto', textAlign: "center", width: width, transform: 'rotateZ(45deg)'}}>{column.headerName}</div>)}*/}
            {/*    </div>*/}
            {/*    {renderRows(rows)}*/}
            {/*</Grid>*/}
            {columnsMore.map((columns, index) => {
                return renderTable(columns, rowsMore[index], tableNames[index])
            })}
        </Grid>
    )
}
