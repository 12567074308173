import {useEffect} from "react";
import FullScreenLoading from "../util/FullScreenLoading";
import {Route, Routes} from "react-router-dom";
import Callback from "./Callback";

const redirect = window.location.origin + '/callback'
const LOGIN_URL = 'https://login.skillpaca.com/login?client_id=6osatmh96ghjd9g66tppnubcbn&response_type=token&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri='+redirect

export default function Login() {

    useEffect(() => {
        window.location = LOGIN_URL;
    }, [])

    return (
        <Routes>
            <Route path="/callback" element={<Callback />} />
            <Route path="/*" element={<FullScreenLoading/>} />
        </Routes>
    )
}
