import React, {useEffect, useState} from 'react';
import { Line } from 'react-chartjs-2';
import 'chartjs-adapter-moment'
import * as d3 from "d3-scale-chromatic";
import {getDateAsMonthOnly, getTotal} from "../../services/Util";

const options = {
    scales: {
        x: {
            type: 'time',
            time: {
                unit: 'month',
                // round: 'month'
            }
        },
        y: {
            // beginAtZero: true,
            stacked: true
        },
    },
    plugins: {
        legend: {
            display: true,
            position: 'top'
        },
        title: {
            display: true,
            text: 'Growth Over Time',
        },
    },
    aspectRatio: 1.2
};

export default function SelfRatingGrowthChart({selfResponses}) {

    const [data,setData] = useState(null);

    useEffect(() => {
        const dataBeforeParse = {}
        let username = ''

        const todaysDate = new Date()
        const todaysDateString = todaysDate.getFullYear() + '-' + (todaysDate.getMonth() + 1) + '-' + todaysDate.getDate()

        const latestScores = {}

        selfResponses.forEach(response => {
            const dateKey = getDateAsMonthOnly(response.modified)
            username = response.username
            if (dataBeforeParse[dateKey] === undefined) {
                dataBeforeParse[dateKey] = {}
            }

            if (dataBeforeParse[dateKey][response.username] === undefined) {

                const total = getTotal(response, 'selfRating')

                dataBeforeParse[dateKey][response.username] = {
                    label: response.targetUserGivenName + ' ' + response.targetUserFamilyName,
                    total: total,
                    modified: new Date(response.modified)
                }

                latestScores[response.username] = total

            } else if (new Date(response.modified) > dataBeforeParse[dateKey][response.username].modified) {

                const total = getTotal(response, 'selfRating')

                dataBeforeParse[dateKey][response.username].total = total
                dataBeforeParse[dateKey][response.username].modified = new Date(response.modified)

                latestScores[response.username] = total

            }

        })

        if (dataBeforeParse[todaysDateString] === undefined) {
            dataBeforeParse[todaysDateString] = {}
            Object.entries(latestScores).forEach(([username, total]) => {
                dataBeforeParse[todaysDateString][username] = {
                    total: total,
                }
            })
        }

        const dataset = {
            datasets: [
                {
                    label: 'Self Rating',
                    data: Object.entries(dataBeforeParse).map(([key, object]) => { return {x: key, y: object[username].total}}),
                    fill: true,
                    backgroundColor: 'rgba(91,16,255,0.2)',
                    borderColor: 'rgba(91,16,255,1)',
                    borderWidth: 1,
                }
            ]
        }

        setData(dataset)

    }, [selfResponses])

    return <Line data={data} options={options}/>

}
