import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import SkillpacaApi from "../../services/SkillpacaApi";
import {useNavigate} from "react-router-dom";
import DeleteIcon from "@material-ui/icons/Delete";

const skillpacaApi = SkillpacaApi()

export default function DeleteLearningLibraryItem({learningLibraryItem, numberOpenClicks, deleteHandler}) {
    const [open, setOpen] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [validationText, setValidationText] = useState('');

    const navigate = useNavigate()

    useEffect(() => {
        if (numberOpenClicks > 0) {
            setValidationText('')
            setOpen(true)
        }
    }, [numberOpenClicks])

    const handleClickOpen = () => {
        setValidationText('')
        setOpen(true);
    };

    const handleClose = () => {
        setValidationText('')
        setOpen(false);
    };

    const handleChange = (e) => {
        setValidationText(e.target.value)
    }

    const canDelete = () => {
        return validationText === 'delete'
    }

    async function deleteResponse() {
        setIsDeleting(true)
        await deleteHandler(learningLibraryItem.id)
        handleClose()
    }

    return (
        <div>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Delete Item: {learningLibraryItem.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        To delete this item please first type "delete" in the box below and then confirm.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        fullWidth
                        onChange={handleChange}
                        value={validationText}
                    />
                </DialogContent>
                <DialogActions>
                    <React.Fragment>
                        <Button onClick={handleClose} disabled={isDeleting} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={deleteResponse} disabled={!canDelete() || isDeleting} color="primary">
                            Confirm
                        </Button>
                    </React.Fragment>
                </DialogActions>
            </Dialog>
        </div>
    );
}
