import AuthService from "./AuthService";

const authService = AuthService()

export const getCurrentUsername = () => {
    return authService.getDecodedJwt().payload["cognito:username"]
}

export const getCurrentGivenName = () => {
    return authService.getDecodedJwt().payload["given_name"]
}

export const getRubric = (survey) => {
    if (survey.rubric && Array.isArray(survey.rubric)) {
        return survey.rubric.map(rubricLevel => rubricLevel.label)
    } else if (survey.skillGroups) {
        let rubric = [1, 2, 3, 4, 5, 6, 7, 8];
        Object.values(survey.skillGroups).forEach(skillGroup => {
            Object.values(skillGroup).forEach(skill => {
                if (skill.rubric && Array.isArray(skill.rubric)) {
                    rubric = skill.rubric.map(rubricLevel => rubricLevel.label)
                }
            })
        });
        return rubric
    } else {
        return [1, 2, 3, 4, 5, 6, 7, 8]
    }
}

export const getDateAsMonthOnly = (dateString) => {
    const date = new Date(dateString)
    return date.getFullYear() + '/' + (date.getMonth() + 1) + '/1'
}

export const getTotal = (response, attribute) => {
    let total = 0
    if (response && response.skillGroups) {
        Object.values(response.skillGroups).forEach(skillGroup => {
            Object.values(skillGroup).forEach(skill => {
                if (skill[attribute]) {
                    total += skill[attribute]
                }
            })
        });
    }
    return total
}
