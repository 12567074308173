
export function prepareResponses(responses) {
    if (responses && Array.isArray(responses)) {
        responses.forEach((response, index) => {
            if (response.skillGroups) {
                Object.entries(response.skillGroups).forEach(([skillGroupId, skillGroupSkills]) => {
                    Object.entries(skillGroupSkills).forEach(([skillId, skill]) => {
                        if (!skill.isSelected) {
                            responses[index].skillGroups[skillGroupId][skillId].selfRating = 0
                            responses[index].skillGroups[skillGroupId][skillId].joyRating = null
                        }
                    })
                })
            } else {
                return []
            }
        })
    } else {
        return []
    }
    return responses
}
