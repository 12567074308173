import {useNavigate, useParams} from "react-router-dom";
import {DataGrid, GridToolbarContainer, GridToolbarExport} from '@material-ui/data-grid';
import React, {useCallback, useEffect, useState} from "react";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import Grid from "@material-ui/core/Grid";
import {Chip, Hidden, Typography} from "@material-ui/core";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import PeerCoachesTable from "./PeerCoachesTable";
import MentoringTable from "./MentoringTable";
import PeerLearningTable from "./PeerLearningTable";
import DeleteResponseButton from "./DeleteResponseButton";
import SkillsSelect from "../util/SkillsSelect";
import Button from "@material-ui/core/Button";
import SkillpacaApi from "../../services/SkillpacaApi";
import SelfRatingRadarChart from "./SelfRatingRadarChart";
import AuthService from "../../services/AuthService";
import RateReviewIcon from '@material-ui/icons/RateReview';
import {getCurrentUsername} from "../../services/Util";
import SelfRatingGrowthChart from "./SelfRatingGrowthChart";
import {formatDistance} from "date-fns";

const authService = AuthService()

const columnsInitial = [
    { field: 'category', headerName: 'Category', flex: 1 },
    { field: 'title', headerName: 'Skill', flex: 1 },
    // { field: 'isSelected', headerName: 'Has the skill', type: 'boolean', flex: 0.6, renderCell: (params) => renderBoolean(params.value) },
    { field: 'selfRating', headerName: 'Self Rating', type: 'number', flex: 0.7, renderCell: (params) =>  renderSelfRating(params) },
    { field: 'yourRating', headerName: 'Your Rating', hide: false, type: 'number', flex: 0.7, renderCell: (params) =>  renderAvgReviewerRating(params)},
    { field: 'joyRating', headerName: 'Joy Rating', type: 'number', flex: 0.6, renderCell: (params) => renderEmoji(params) },
    { field: 'isLearn', headerName: 'Interested in Learning', type: 'boolean', flex: 1, renderCell: (params) => renderBoolean(params.value)}
];

function renderBoolean(value) {
    return value ? <CheckCircleIcon style={{color: 'green'}}/>: <CancelIcon style={{color: 'red'}}/>
}

function renderEmoji(params) {

    let emoji = ''
    switch (params.value) {
        case 1:
            emoji = <span>😫</span>
            break;
        case 2:
            emoji = <span>🙂</span>
            break;
        case 3:
            emoji = <span>😍</span>
            break;
        default:
            emoji = <span/>
    }

    if(params.row.previousJoyRating !== null) {
        const score1 = params.value
        const score2 = params.row.previousJoyRating
        if (score1 > score2) {
            return <span>{emoji}<span style={{color: '#17d517', fontSize: 14}}>&nbsp;&nbsp;&nbsp;▲ {score1 - score2}</span></span>
        } else if (score2 > score1) {
            return <span>{emoji}<span style={{color: 'red', fontSize: 14}}>&nbsp;&nbsp;&nbsp;▼ {score2 - score1}</span></span>
        } else {
            return <span>{emoji}<span style={{color: '#00000000', fontSize: 14}}>&nbsp;&nbsp;&nbsp;▲ 0</span></span>
        }
    }
    return <span>{emoji}<span style={{color: '#00000000', fontSize: 14}}>&nbsp;&nbsp;&nbsp;▲ 0</span></span>
}

function renderSelfRating(params) {
    // console.log(params)
    if(params.row.previousSelfRating !== null) {
        const score1 = params.value
        const score2 = params.row.previousSelfRating
        if (score1 > score2) {
            return <span>{score1}<span style={{color: '#17d517', fontSize: 14}}>&nbsp;&nbsp;&nbsp;▲ {score1 - score2}</span></span>
        } else if (score2 > score1) {
            return <span>{score1}<span style={{color: 'red', fontSize: 14}}>&nbsp;&nbsp;&nbsp;▼ {score2 - score1}</span></span>
        } else {
            return <span>{score1}<span style={{color: '#00000000', fontSize: 14}}>&nbsp;&nbsp;&nbsp;▲ 0</span></span>
        }
    } else {
        return params.value
    }
}

function renderAvgReviewerRating(params) {
    if (params.value === params.row.selfRating) {
        // return <Chip variant={"outlined"} label={params.value} />
        return <span>{params.value}<span style={{color: '#00000000', fontSize: 14}}>&nbsp;&nbsp;&nbsp;▲ 0</span></span>
        // return <span>{params.value}</span>
    } else if (params.value > params.row.selfRating) {
        // return <Chip label={params.value} style={{color: 'white', backgroundColor: 'green'}} />
        return <span>{params.value}<span style={{color: '#17d517', fontSize: 14}}>&nbsp;&nbsp;&nbsp;▲ {params.value - params.row.selfRating}</span></span>
        // return <span style={{color: 'green'}}><strong>{params.value}</strong></span>
    } else {
        return <span>{params.value}<span style={{color: 'red', fontSize: 14}}>&nbsp;&nbsp;&nbsp;▼ {params.row.selfRating - params.value}</span></span>
        // return <Chip label={params.value} style={{color: 'white', backgroundColor: 'red'}} />
        // return <span style={{color: 'red'}}><strong>{params.value}</strong></span>
    }
}

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarExport />
        </GridToolbarContainer>
    );
}

function track(username, targetUsername) {
    if (window && window.analytics) {
        window.analytics.track("Clicked review", {"username": username, "targetUsername": targetUsername});
    }
}

export default function IndividualResponse({survey, responses}) {
    const {username} = useParams()
    const navigate = useNavigate()
    const [response, setResponse] = useState(null)
    const [selfResponses, setSelfResponses] = useState([])

    const [rows, setRows] = useState([])
    const [selection, setSelection] = useState([])
    const [filterSkills, setFilterSkills] = useState([])
    const [filterSkillsPeerCoaches, setFilterSkillsPeerCoaches] = useState([])
    const [filterSkillsMentoring, setFilterSkillsMentoring] = useState([])
    const [filterSkillsPeerLearning, setFilterSkillsPeerLearning] = useState([])
    const [reviewerResponses, setReviewerResponses] = useState([]);
    const [columns, setColumns] = useState(columnsInitial)
    const [isReviewLinkLoading, setIsReviewLinkLoading] = useState(false)
    const [reviewLink, setReviewLink] = useState('')

    const updateRows = useCallback(((individualsResponse, previousReponse) => {
        const rows = []

        const columns = columnsInitial
        columns[3].hide = true

        if (individualsResponse) {
            Object.entries(individualsResponse.skillGroups).forEach(([skillGroupId, skillGroupSkills]) => {
                Object.entries(skillGroupSkills).forEach(([skillId, skill]) => {

                    let rowData = {}

                    if (!filterSkills.length || filterSkills.includes(skillId)) {
                        rowData = {
                            id: skillId,
                            category: skill.category,
                            title: skill.title,
                            isSelected: skill.isSelected,
                            selfRating: skill.isSelected ? skill.selfRating : null,
                            joyRating: skill.isSelected ? skill.joyRating : null,
                            isLearn: skill.isLearn
                        }

                        if (previousReponse && previousReponse.skillGroups && previousReponse.skillGroups[skillGroupId] && previousReponse.skillGroups[skillGroupId][skillId]) {
                            rowData.previousSelfRating = previousReponse.skillGroups[skillGroupId][skillId].selfRating
                            rowData.previousJoyRating = previousReponse.skillGroups[skillGroupId][skillId].joyRating
                        } else {
                            rowData.previousSelfRating = null
                            rowData.previousJoyRating = null
                        }

                        if (reviewerResponses && Array.isArray(reviewerResponses) && reviewerResponses.length) {
                            // let reviewerRatingTotal = 0
                            // let reviewerRatingCount = 0
                            //
                            // reviewerResponses.forEach(response => {
                            //     reviewerRatingCount++
                            //     columns[3].hide = false
                            //     if (response.skillGroups && response.skillGroups[skillGroupId] && response.skillGroups[skillGroupId][skillId] && response.skillGroups[skillGroupId][skillId].selfRating) {
                            //         reviewerRatingTotal += response.skillGroups[skillGroupId][skillId].selfRating
                            //     }
                            //
                            // })
                            //
                            // rowData.yourRating = reviewerRatingCount ? reviewerRatingTotal / reviewerRatingCount : 0

                            const latestReviewerResponse = reviewerResponses[(reviewerResponses.length - 1)]
                            if (latestReviewerResponse.skillGroups && latestReviewerResponse.skillGroups[skillGroupId] && latestReviewerResponse.skillGroups[skillGroupId][skillId] && latestReviewerResponse.skillGroups[skillGroupId][skillId].selfRating) {
                                rowData.yourRating = latestReviewerResponse.skillGroups[skillGroupId][skillId].selfRating
                            } else {
                                rowData.yourRating = 0
                            }
                            columns[3].hide = false

                        }

                        rows.push(rowData)
                    }

                })
            })
        }
        setColumns(columns)
        setRows(rows)
    }), [filterSkills, reviewerResponses])

    useEffect(() => {
        // let individualsResponse = null
        const reviewerResponsesLocal = []
        const selfResponsesLocal = []

        if (responses && Array.isArray(responses)) {
            responses.forEach(response => {

                if (response.status === "COMPLETED" && response.targetUsername === username) {
                    if (response.username === username) {
                        selfResponsesLocal.push(response)
                    } else {
                        reviewerResponsesLocal.push(response)
                    }
                }
                // if (response.status === "COMPLETED" && response.username === username && ) {
                // }
                //
                // if (response.targetUsername && response.targetUsername === username && response.username !== username) {
                //     if (response.status === "COMPLETED") {
                //     }
                // }
            })

            // if (individualsResponse) {
            //     responses.forEach(response => {
            //         if (response.targetUsername && response.targetUsername === individualsResponse.username && response.username !== individualsResponse.username) {
            //             if (response.status === "COMPLETED") {
            //                 reviewerResponsesLocal.push(response)
            //             }
            //         }
            //     })
            // }
        }

        setResponse(selfResponsesLocal[(selfResponsesLocal.length - 1)])
        setSelfResponses(selfResponsesLocal)
        setReviewerResponses(reviewerResponsesLocal)

    }, [username, responses])

    useEffect(() => {
        const previousResponse = selfResponses.length > 1 ? selfResponses[selfResponses.length-2] : null
        updateRows(response, previousResponse)
    }, [updateRows, filterSkills, response, selfResponses])

    useEffect(() => {
        setIsReviewLinkLoading(true)
        if (response && survey) {
            SkillpacaApi().createResponse(survey.id, response.userId, response.username).then(jwt => {
                const surveyLink = "https://survey.skillpaca.com/survey/" + authService.getDecodedJwt().payload['custom:tenant_id'] + "/" + survey.id + "?jwt=" + jwt
                setReviewLink(surveyLink)
                setIsReviewLinkLoading(false)
            })
        }
    }, [response, survey])

    function handleSkillsSelectChange(values) {
        setFilterSkills(values.map(value => value.value))
    }

    function handleSkillsSelectPeerCoachesChange(values) {
        setFilterSkillsPeerCoaches(values.map(value => value.value))
    }

    function handleSkillsSelectMentoringChange(values) {
        setFilterSkillsMentoring(values.map(value => value.value))
    }

    function handleSkillsSelectPeerLearningChange(values) {
        setFilterSkillsPeerLearning(values.map(value => value.value))
    }

    if (!response) {
        return <div/>
    }

    function clickedReview() {
        track(getCurrentUsername(), response.username)
    }

    function getReviewButtonText() {
        if (reviewerResponses && Array.isArray(reviewerResponses) && reviewerResponses.length > 0) {
            return "Update Review"
        }
        return "Do Review"
    }

    return (
        <Grid container>
            <Grid item xs={10} style={{marginBottom: 16, marginTop: 8}}>
                <Typography variant={"h5"}><span  onClick={() => navigate(-1)} style={{cursor: 'pointer'}}><ArrowBackIcon style={{verticalAlign: 'middle'}}/> {response.targetUserGivenName} {response.targetUserFamilyName}</span></Typography>
                <Typography variant={"subtitle1"}><span  onClick={() => navigate(-1)} style={{cursor: 'pointer'}}>Last Check-in: {formatDistance(new Date(response.modified), new Date(), { addSuffix: true })}</span></Typography>
            </Grid>
            <Grid item xs={2} style={{marginBottom: 16, marginTop: 8}}>
                <DeleteResponseButton responseId={response.id}/>
            </Grid>
            <Hidden smDown><Grid item md={1}/></Hidden>
            <Grid item xs={12} md={4}>
                <SelfRatingGrowthChart selfResponses={selfResponses}/>
            </Grid>
            <Hidden smDown><Grid item md={2}/></Hidden>
            <Grid item xs={12} md={4}>
                <SelfRatingRadarChart reviewerResponses={reviewerResponses} response={response} survey={survey} />
            </Grid>
            <Grid item md={8} style={{marginBottom: 16, marginTop: 16}}>
                {isReviewLinkLoading ? '' : <Button onClick={clickedReview} href={reviewLink} target={"_blank"} color={"primary"} variant={"contained"} endIcon={<RateReviewIcon/>}>{getReviewButtonText()}</Button>}
            </Grid>
            <Grid item xs={12} md={4} style={{marginBottom: 16, marginTop: 8}}>
                <SkillsSelect survey={survey} onChange={handleSkillsSelectChange} isMulti={true}/>
            </Grid>
            <Grid item xs={12}>
                <div style={{ width: '100%' }}>
                    <DataGrid autoHeight
                        rows={rows}
                        columns={columns}
                        checkboxSelection
                        components={{
                            Toolbar: CustomToolbar,
                        }}
                        onSelectionModelChange={(newSelection) => {
                            setSelection(newSelection.selectionModel);
                        }}
                    />
                </div>
            </Grid>
            {/*<Grid item xs={12} style={{marginTop: 32}}>*/}
            {/*    <Typography variant={"h4"}>Peer Learning</Typography>*/}
            {/*</Grid>*/}

            {/*<Grid item xs={12} md={8} style={{marginBottom: 16, marginTop: 32}}>*/}
            {/*    <Typography variant={"h5"}>Peers that {response.name} could learn from</Typography>*/}
            {/*    <Typography variant={"subtitle1"}>Skills that {response.name} is interested in learning where peers have rated themselves higher than {response.name}.</Typography>*/}
            {/*</Grid>*/}
            {/*<Grid item xs={12} md={4} style={{marginBottom: 16, marginTop: 'auto'}}>*/}
            {/*    <SkillsSelect survey={survey} onChange={handleSkillsSelectPeerCoachesChange} isMulti={true}/>*/}
            {/*</Grid>*/}
            {/*<Grid item xs={12}>*/}
            {/*    <div style={{ width: '100%' }}>*/}
            {/*        <PeerCoachesTable responseId={response.id} survey={survey} responses={responses} filterSkills={filterSkillsPeerCoaches}/>*/}
            {/*    </div>*/}
            {/*</Grid>*/}

            {/*<Grid item xs={12} md={8} style={{marginBottom: 16, marginTop: 32}}>*/}
            {/*    <Typography variant={"h5"}>Peers that {response.name} can help coach</Typography>*/}
            {/*    <Typography variant={"subtitle1"}>Skills that {response.name}'s peers are interested in learning where they have rated themselves lower than {response.name}.</Typography>*/}
            {/*</Grid>*/}
            {/*<Grid item xs={12} md={4} style={{marginBottom: 16, marginTop: 'auto'}}>*/}
            {/*    <SkillsSelect survey={survey} onChange={handleSkillsSelectMentoringChange} isMulti={true}/>*/}
            {/*</Grid>*/}
            {/*<Grid item xs={12}>*/}
            {/*    <div style={{ width: '100%' }}>*/}
            {/*        <MentoringTable responseId={response.id} survey={survey} responses={responses} filterSkills={filterSkillsMentoring}/>*/}
            {/*    </div>*/}
            {/*</Grid>*/}

            {/*<Grid item xs={12} md={8} style={{marginBottom: 16, marginTop: 32}}>*/}
            {/*    <Typography variant={"h5"}>Group learning opportunities</Typography>*/}
            {/*    <Typography variant={"subtitle1"}>Skills that both {response.name} and peers are interested in learning and have rated themselves similarly.</Typography>*/}
            {/*</Grid>*/}
            {/*<Grid item xs={12} md={4} style={{marginBottom: 16, marginTop: 'auto'}}>*/}
            {/*    <SkillsSelect survey={survey} onChange={handleSkillsSelectPeerLearningChange} isMulti={true}/>*/}
            {/*</Grid>*/}
            {/*<Grid item xs={12}>*/}
            {/*    <div style={{ width: '100%' }}>*/}
            {/*        <PeerLearningTable responseId={response.id} survey={survey} responses={responses} filterSkills={filterSkillsPeerLearning}/>*/}
            {/*    </div>*/}
            {/*</Grid>*/}
        </Grid>
    )
}
