import React, {useEffect, useState} from 'react';
import { Line } from 'react-chartjs-2';
import 'chartjs-adapter-moment'
import * as d3 from "d3-scale-chromatic";
import {getDateAsMonthOnly, getTotal} from "../../services/Util";

const options = {
    scales: {
        x: {
            type: 'time',
            time: {
                unit: 'month',
                round: 'month'
            }
        },
        y: {
            beginAtZero: true,
            stacked: true
        },
    },
    plugins: {
        legend: {
            display: true,
            position: 'bottom'
        }
    },
    aspectRatio: 3.5
};

const TeamGrowthChart = ({responses}) => {

    const [data,setData] = useState(null);

    useEffect(() => {

        const dataBeforeParse = {}

        const todaysDate = new Date()
        const todaysDateString = todaysDate.getFullYear() + '-' + (todaysDate.getMonth() + 1) + '-1'

        if (responses) {

            const selfResponses = responses.filter(response => {
                return response.username === response.targetUsername && response.username
            })

            const latestScores = {}

            selfResponses.sort((a, b) => new Date(a.modified) - new Date(b.modified))
            selfResponses.forEach(response => {
                const dateKey = getDateAsMonthOnly(response.modified)
                if (dataBeforeParse[dateKey] === undefined) {
                    dataBeforeParse[dateKey] = {}
                }

                if (dataBeforeParse[dateKey][response.username] === undefined) {
                    const total = getTotal(response, 'selfRating')
                    dataBeforeParse[dateKey][response.username] = {
                        label: response.targetUserGivenName + ' ' + response.targetUserFamilyName,
                        total: total,
                        modified: new Date(response.modified)
                    }

                    latestScores[response.username] = total

                } else if (new Date(response.modified) > dataBeforeParse[dateKey][response.username].modified) {
                    const total = getTotal(response, 'selfRating')

                    dataBeforeParse[dateKey][response.username].total = total
                    dataBeforeParse[dateKey][response.username].modified = new Date(response.modified)

                    latestScores[response.username] = total
                }

            })

            if (dataBeforeParse[todaysDateString] === undefined) {
                dataBeforeParse[todaysDateString] = {}
                Object.entries(latestScores).forEach(([username, total]) => {
                    dataBeforeParse[todaysDateString][username] = {
                        total: total,
                    }
                })
            }

            let previousValues = {}
            const usersDatasets = {}
            let previousDate = null
            let usersDatasetsIndex = 0

            Object.entries(dataBeforeParse).forEach(([date, values]) => {
               const cumulativeData = {...previousValues, ...values}

                Object.entries(cumulativeData).forEach(([username, object]) => {
                    if (!usersDatasets[username]) {
                        usersDatasets[username] = {
                            label: object.label,
                            data: [],
                            fill: true,
                            backgroundColor: d3.schemeSet3[usersDatasetsIndex % 10],
                            borderColor: d3.schemeSet3[usersDatasetsIndex % 10],
                        }
                        usersDatasetsIndex++

                        if (previousDate) {
                            usersDatasets[username].data.push({x: previousDate, y: 0})
                        }
                    }
                    usersDatasets[username].data.push({x: date, y: object.total})
                })

                previousValues = cumulativeData
                previousDate = date
            })

            const dataSet = {
                datasets: Object.values(usersDatasets),
            }

            setData(dataSet)
        }
    }, [responses])

    return <Line data={data} options={options}/>
};

export default TeamGrowthChart;
