import ResponsiveDrawer, {ListItems} from "../ResponsiveDrawer";
import React, {useEffect, useState} from "react";
import SkillpacaApi from "../../services/SkillpacaApi";
import {useParams} from "react-router-dom";
import FullScreenLoading from "../util/FullScreenLoading";
import {Grid, makeStyles, Paper, Typography} from "@material-ui/core";
import "../../charts/beeswarm/css/vis-skills-beeswarm.css"
import "../../charts/beeswarm/css/vis-tooltip.css"
import "../../charts/beeswarm/css/vis-togglable-chips.css"
import {getCurrentGivenName, getDateAsMonthOnly, getTotal} from "../../services/Util";
import SelfRatingOverviewRadarChart from "./SelfRatingOverviewRadarChart";
import TeamGrowthChart from "./TeamGrowthChart";
import {addDays, differenceInDays, formatDistance} from 'date-fns'

const skillpacaApi = SkillpacaApi()

const useStyles = makeStyles((theme) => ({
    borderBox: {
        // border: "solid",
        // borderWidth: '1px',
        // borderRadius: '15px',
        // borderColor: '#afafaf',
        padding: '16px',
        textAlign: 'center',
    },
    heroStat: {
        fontSize: '2.125rem',
        fontWeight: 'bold',
        lineHeight: '1.235'
    },
    heroStatSub: {
        fontSize: '1.5rem',
    }
}))

export default function Overview() {

    const classes = useStyles()

    const {surveyId} = useParams();

    const [isLoading, setIsLoading] = useState(true);
    const [survey, setSurvey] = useState(null);
    const [responses, setResponses] = useState([]);
    const [checkinSchedule, setCheckinSchedule] = useState({});

    useEffect(() => {
        Promise.all([skillpacaApi.getSurvey(surveyId), skillpacaApi.getResponses(surveyId)]).then(([survey, responses]) => {
            setSurvey(survey)
            setIsLoading(false)

            const checkinSchedule = {}
            const selfResponses = responses.filter(response => {
                return response.username === response.targetUsername && response.username && response.status === "COMPLETED"
            })

            selfResponses.forEach(response => {
                const modifiedDate = new Date(response.modified)
                if (checkinSchedule[response.username]) {
                    if (checkinSchedule[response.username].date - modifiedDate < 0 ) {
                        checkinSchedule[response.username].date = modifiedDate
                    }
                } else {
                    checkinSchedule[response.username] = {
                        date: modifiedDate,
                        givenName: response.targetUserGivenName,
                        familyName: response.targetUserFamilyName
                    }
                }
            })

            setCheckinSchedule(checkinSchedule)
            setResponses(selfResponses)
        })
    }, [surveyId])

    function renderDelta(score1, score2) {
        if (score1 > score2) {
            return <span style={{color: '#17d517', fontSize: 18}}>&nbsp;&nbsp;▲ {(score1 - score2).toFixed(2)}</span>
        } else if (score2 > score1) {
            return <span style={{color: 'red', fontSize: 18}}>&nbsp;&nbsp;▼ {Math.abs(score1 - score2).toFixed(2)}</span>
        } else {
            return <span style={{color: 'grey', fontSize: 18}}>&nbsp;&nbsp;— 0</span>
        }
    }

    function getTotalPossibleJoyRating(response) {
        let total = 0
        if (response && response.skillGroups) {
            Object.values(response.skillGroups).forEach(skillGroup => {
                Object.values(skillGroup).forEach(skill => {
                    if (skill.isSelected) {
                        total += 3
                    }
                })
            });
        }
        return total
    }

    function renderHeroCards(responses) {

        const dataBeforeParse = {}

        responses.sort((a, b) => new Date(a.modified) - new Date(b.modified))
        responses.forEach(response => {
            const dateKey = getDateAsMonthOnly(response.modified)
            if (dataBeforeParse[dateKey] === undefined) {
                dataBeforeParse[dateKey] = {}
            }
            if (dataBeforeParse[dateKey][response.username] === undefined) {
                dataBeforeParse[dateKey][response.username] = {
                    label: response.targetUserGivenName + ' ' + response.targetUserFamilyName,
                    totalSelfRating: getTotal(response, 'selfRating'),
                    totalJoyRating: getTotal(response, 'joyRating'),
                    totalPossibleJoyRating: getTotalPossibleJoyRating(response),
                    modified: new Date(response.modified)
                }

            } else if (new Date(response.modified) > dataBeforeParse[dateKey][response.username].modified) {
                dataBeforeParse[dateKey][response.username].totalSelfRating = getTotal(response, 'selfRating')
                dataBeforeParse[dateKey][response.username].totalJoyRating = getTotal(response, 'joyRating')
                dataBeforeParse[dateKey][response.username].totalPossibleJoyRating = getTotalPossibleJoyRating(response)
                dataBeforeParse[dateKey][response.username].modified = new Date(response.modified)
            }

        })


        let filledOutData = {}
        let previousDate = null
        Object.entries(dataBeforeParse).forEach(([date, values]) => {
            filledOutData[date] = {...filledOutData[previousDate], ...values}
            previousDate = date
        })


        let largestDateString = 0
        let secondLargestDateString = 0

        Object.keys(dataBeforeParse).forEach((dateString) => {
            const date = new Date(dateString)
            if (date - new Date(largestDateString)> 0) {
                secondLargestDateString = largestDateString
                largestDateString = dateString
            }

            if (date - new Date(secondLargestDateString) > 0 && date - new Date(largestDateString) < 0) {
                secondLargestDateString = dateString
            }

        })

        let currentTotalSelfRating = 0
        let previousTotalSelfRating = 0
        let currentTotalJoyRating = 0
        let previousTotalJoyRating = 0
        let currentTotalPossibleJoyRating = 0
        let previousTotalPossibleJoyRating = 0

        if (largestDateString) {
            Object.values(filledOutData[largestDateString]).forEach(dataObject => {
                currentTotalSelfRating += dataObject.totalSelfRating
                currentTotalJoyRating += dataObject.totalJoyRating
                currentTotalPossibleJoyRating += dataObject.totalPossibleJoyRating
            })
        }

        if (secondLargestDateString) {
            Object.values(filledOutData[secondLargestDateString]).forEach(dataObject => {
                previousTotalSelfRating += dataObject.totalSelfRating
                previousTotalJoyRating += dataObject.totalJoyRating
                previousTotalPossibleJoyRating += dataObject.totalPossibleJoyRating
            })
        }


        const currentJoyPercentage = currentTotalPossibleJoyRating > 0 ? ((currentTotalJoyRating / currentTotalPossibleJoyRating) * 100).toFixed(0) : 0
        const previousJoyPercentage = previousTotalPossibleJoyRating > 0 ? ((previousTotalJoyRating / previousTotalPossibleJoyRating) * 100).toFixed(0) : 0
        const growthRatePercentage = previousTotalSelfRating > 0 ? (((currentTotalSelfRating/previousTotalSelfRating) - 1) * 100).toFixed(2) : 0

        return (
            <React.Fragment>
                <Grid item xs={12} md={4}>
                    <Paper style={{padding: 24}}>
                        <Typography variant="body2">Total Skill Score</Typography>
                        <Typography variant="h5"><strong>{currentTotalSelfRating} {renderDelta(currentTotalSelfRating, previousTotalSelfRating)}</strong></Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Paper style={{padding: 24}}>
                        <Typography variant="body2">Growth Rate ( Target 2% )</Typography>
                        <Typography variant="h5"><strong>{growthRatePercentage + '%'} {renderDelta(growthRatePercentage, 2)}</strong></Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Paper style={{padding: 24}}>
                        <Typography variant="body2">Overall Joy</Typography>
                        <Typography variant="h5"><strong>{currentJoyPercentage + '%'} {renderDelta(currentJoyPercentage, previousJoyPercentage)}</strong></Typography>
                    </Paper>
                </Grid>
            </React.Fragment>
        )

    }

    function renderCheckinSchedule(checkinSchedule) {
        const checkins = []
        Object.entries(checkinSchedule).forEach(([username, user]) => {
            checkins.push({username: username, ...user})
        })

        checkins.sort((a, b) => a.date - b.date)

        const jsx = []
        checkins.forEach(user => {
            const checkinDate = addDays(user.date, 90)
            const isOverdue = differenceInDays(checkinDate, new Date()) <= 0
            jsx.push(
                <Grid key={user.username} item xs={12} style={{paddingBottom: 16}}>
                    <Grid container spacing={3}>
                        <Grid item xs={4}>
                            {isOverdue ? <span style={{color: '#ff3232'}}><strong>{user.givenName}&nbsp;{user.familyName}</strong></span> : <span>{user.givenName}&nbsp;{user.familyName}</span>}
                        </Grid>
                        <Grid item xs={4}>
                            {isOverdue ? <span style={{color: '#ff3232'}}><strong>{formatDistance(user.date, new Date(), { addSuffix: true })}</strong></span> : <span>{formatDistance(user.date, new Date(), { addSuffix: true })}</span>}
                        </Grid>
                        <Grid item xs={4}>
                            {isOverdue ? <span style={{color: '#ff3232'}}><strong>{formatDistance(checkinDate, new Date(), { addSuffix: true })}</strong></span> : <span>{formatDistance(checkinDate, new Date(), { addSuffix: true })}</span>}
                        </Grid>
                        {/*<Grid item xs={1}>*/}
                        {/*    {differenceInDays(checkinDate, new Date()) < 0 ? <Chip color={"primary"} label={"Remind"}/> : '' }*/}
                        {/*</Grid>*/}
                    </Grid>
                </Grid>
            )
        })
        return (
            <Grid container>
                <Grid item xs={12} style={{paddingBottom: 16}}>
                    <Grid container spacing={3}>
                        <Grid item xs={4}>
                        </Grid>
                        <Grid item xs={4}>
                            <strong>Last Checkin</strong>
                        </Grid>
                        <Grid item xs={4}>
                            <strong>Next Checkin</strong>
                        </Grid>
                    </Grid>
                </Grid>
                {jsx}
            </Grid>
        )
    }

    if (isLoading) {
        return (
            <ResponsiveDrawer surveyId={surveyId} activeItem={ListItems.overview}>
                <FullScreenLoading/>
            </ResponsiveDrawer>
        )
    }

    return (
        <ResponsiveDrawer surveyId={surveyId} activeItem={ListItems.overview}>
            <Typography variant="h6">Welcome back, {getCurrentGivenName()}! 👋</Typography>
            <br/>
            <Grid container justify="center">
                <Grid item lg={10} md={11} xs={12}>
                    <Grid container spacing={3}>
                        {renderHeroCards(responses)}
                        <Grid item xs={12}>
                            <Paper>
                                <Grid container justify="flex-start" style={{padding: 24}}>
                                    <Grid item xs={12} style={{paddingBottom: 16}}>
                                        <Typography variant="h5">Team Growth</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TeamGrowthChart responses={responses}/>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Paper>
                                <Grid container justify="flex-start" style={{padding: 24}}>
                                    <Grid item xs={12} style={{paddingBottom: 16}}>
                                        <Typography variant="h5">Skill Checkins</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {renderCheckinSchedule(checkinSchedule)}
                                        {/*<TeamGrowthChart responses={responses}/>*/}
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                        <Grid item md={6} xs={12} style={{height: '100%'}}>
                            <Paper style={{height: '100%'}}>
                                <Grid container justify="flex-start" style={{padding: 24}}>
                                    <Grid item xs={12} style={{paddingBottom: 16}}>
                                        <Typography variant="h5">Skills Overview</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <SelfRatingOverviewRadarChart responses={responses} survey={survey} />
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

        </ResponsiveDrawer>
    )
}
