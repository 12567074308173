import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import SkillpacaApi from "../../services/SkillpacaApi";
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import SkillsSelect from "../util/SkillsSelect";
import {FormControl, makeStyles} from "@material-ui/core";
import LearningLibraryItemTypeSelect from "./LearningLibraryItemTypeSelect";
import {getRubric} from "../../services/Util";
import Select from "react-select";

const skillpacaApi = SkillpacaApi()

const useStyles = makeStyles((theme) => ({
    inputs: {
        marginTop: '24px',
    }
}))

export default function AddLearningLibraryItemButton({survey, onSubmitCallback}) {
    const [open, setOpen] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [skills, setSkills] = useState([])
    const [levels, setLevels] = useState([])
    const [type, setType] = useState('')
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [url, setUrl] = useState('')

    const classes = useStyles()

    const rubric = getRubric(survey)

    const handleClickOpen = () => {
        setTitle('')
        setDescription('')
        setUrl('')
        setOpen(true);
    };

    const handleClose = () => {
        setTitle('')
        setDescription('')
        setUrl('')
        setOpen(false);
    };

    const canSubmit = () => {
        return skills.length > 0
    }

    const handleSkillSelectChange = (optionSelected) => {
        setSkills(optionSelected.map((selectedSkill) => selectedSkill.value))
    }

    const handleLearningLibraryItemTypeSelectChange = (optionSelected) => {
        setType(optionSelected.value)
    }

    const handleRubricSelectChange = (optionSelected) => {
        setLevels(optionSelected.map((selectedLevel) => selectedLevel.value))
    }

    async function submit() {
        setIsSubmitting(true)
        await skillpacaApi.insertLearningLibraryItem(survey.id, {
            skills: skills,
            levels: levels,
            type: type,
            title: title,
            description: description,
            url: url
        })
        if (onSubmitCallback) {
            onSubmitCallback()
        }
        setOpen(false)
    }

    return (
        <div>
            <Button variant={"contained"} color={"primary"} startIcon={<LibraryAddIcon />} onClick={handleClickOpen}>
                Add an item
            </Button>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" scroll="body" PaperProps={{style: {overflowY: 'visible'}}}>
                <DialogTitle id="form-dialog-title">Add an item to the Learning Library</DialogTitle>
                <DialogContent style={{ overflowY: 'visible' }}>
                    <DialogContentText>
                        Select the skills you want your content to be linked to.
                    </DialogContentText>
                    <FormControl fullWidth className={classes.inputs}>
                        <SkillsSelect survey={survey} onChange={handleSkillSelectChange} isMulti={true}/>
                    </FormControl>
                    <FormControl fullWidth className={classes.inputs}>
                        <Select
                            defaultValue={[]}
                            onChange={handleRubricSelectChange}
                            options={rubric.map((label) => {
                                return {value: label, label: label}
                            })}
                            isMulti={true}
                            placeholder={"Select the levels this content is useful for"}
                            closeMenuOnSelect={false}
                        />
                    </FormControl>
                    <FormControl fullWidth className={classes.inputs}>
                        <LearningLibraryItemTypeSelect isMulti={false} onChange={handleLearningLibraryItemTypeSelectChange}/>
                    </FormControl>
                    <TextField
                        autoFocus
                        id="title"
                        fullWidth
                        onChange={(e) => setTitle(e.target.value)}
                        placeholder={"Title"}
                        helperText="A title for the content"
                        value={title}
                        className={classes.inputs}
                    />
                    <TextField
                        id="description"
                        fullWidth
                        onChange={(e) => setDescription(e.target.value)}
                        placeholder={"Description"}
                        helperText="A short description of the content"
                        value={description}
                        className={classes.inputs}
                    />
                    <TextField
                        id="url"
                        fullWidth
                        onChange={(e) => setUrl(e.target.value)}
                        placeholder={"Url"}
                        helperText="A link to where the content can be found"
                        value={url}
                        className={classes.inputs}
                    />
                </DialogContent>
                <DialogActions>
                    <React.Fragment>
                        <Button onClick={handleClose} disabled={isSubmitting} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={submit} disabled={!canSubmit() || isSubmitting} color="primary">
                            Confirm
                        </Button>
                    </React.Fragment>
                </DialogActions>
            </Dialog>
        </div>
    );
}
