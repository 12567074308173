import {useNavigate} from "react-router-dom";
import {Paper} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {formatDistance} from "date-fns";

export default function TeamList({survey, responses}) {

    const navigate = useNavigate()

    function getTotal(response, attribute) {
        let total = 0
        if (response && response.skillGroups) {
            Object.values(response.skillGroups).forEach(skillGroup => {
                Object.values(skillGroup).forEach(skill => {
                    if (skill[attribute]) {
                        total += skill[attribute]
                    }
                })
            });
        }
        return total
    }

    function getIsLearnSkills(response) {
        const isLearnSkills = []
        if (response && response.skillGroups) {
            Object.values(response.skillGroups).forEach(skillGroup => {
                Object.values(skillGroup).forEach(skill => {
                    if (skill.isLearn) {
                        isLearnSkills.push(skill.title)
                    }
                })
            });
        }
        return isLearnSkills
    }

    function getTotalPossibleJoyRating(response) {
        let total = 0
        if (response && response.skillGroups) {
            Object.values(response.skillGroups).forEach(skillGroup => {
                Object.values(skillGroup).forEach(skill => {
                    if (skill.isSelected) {
                        total += 3
                    }
                })
            });
        }
        return total
    }

    function renderDelta(score1, score2) {
        if (score1 > score2) {
            return <span style={{color: '#17d517', fontSize: 14}}>&nbsp;&nbsp;&nbsp;▲ {score1 - score2}</span>
        } else if (score2 > score1) {
            return <span style={{color: 'red', fontSize: 14}}>&nbsp;&nbsp;&nbsp;▼ {score2 - score1}</span>
        } else {
            return <span style={{color: 'grey', fontSize: 14}}>&nbsp;&nbsp;&nbsp;— 0</span>
        }
    }

    function renderIsLearnSkills(response) {
        const isLearnSkills = getIsLearnSkills(response)
        return isLearnSkills.map((skillTitle, index) => {
            if (index < 3) {
                return (
                    <Grid key={skillTitle} item xs={4} style={{textAlign: 'center'}}>
                        <Typography variant={"body1"} color={"primary"} >{skillTitle}</Typography>
                    </Grid>
                )
            }
            return ''
        })

    }

    function renderTeamMembers(responses) {
        let cardJsx = []
        if (responses) {

            const selfResponses = responses.filter(response => response.status === "COMPLETED" && response.targetUsername === response.username && response.username)
            // selfResponses.sort((a, b) => new Date(a.modified) - new Date(b.modified))

            const usersResponses = {}
            selfResponses.forEach(response => {
                if (usersResponses[response.targetUsername]) {
                    usersResponses[response.targetUsername] = [response, ...usersResponses[response.targetUsername]]
                } else {
                    usersResponses[response.targetUsername] = [response]
                }
            })

            cardJsx = Object.entries(usersResponses).map(([username, responses]) => {

                const selfRating0 = getTotal(responses[0], 'selfRating')
                const joyRating0 = getTotal(responses[0], 'joyRating')
                const totalPossibleJoyRating0 = getTotalPossibleJoyRating(responses[0])

                const selfRating1 = getTotal(responses[1], 'selfRating')
                const joyRating1 = getTotal(responses[1], 'joyRating')
                const totalPossibleJoyRating1 = getTotalPossibleJoyRating(responses[1])

                const joyRatingPercentage0 = totalPossibleJoyRating0 ? (joyRating0/totalPossibleJoyRating0 * 100).toFixed(0) : 0
                const joyRatingPercentage1 = totalPossibleJoyRating1 ? (joyRating1/totalPossibleJoyRating1 * 100).toFixed(0) : 0

                return (
                    <Grid item key={username} xs={12} md={6}>
                        <Paper onClick={() => navigate(encodeURIComponent(username))} style={{cursor: "pointer", height: '100%', padding: 24}}>
                            <Grid container justify={"center"} style={{textAlign: 'center'}} spacing={3}>
                                <Grid item xs={12} style={{textAlign: 'center'}}>
                                    <Typography variant={"h6"}>{responses[0].targetUserGivenName} {responses[0].targetUserFamilyName}</Typography>
                                    <Typography variant={"subtitle2"}>Last Checkin: {formatDistance(new Date(responses[0].modified), new Date(), { addSuffix: true })}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant={"body1"}>Skill Progression</Typography>
                                    <Typography variant={"h6"}>{selfRating0}{responses[1] ? renderDelta(selfRating0, selfRating1): ''}</Typography>
                                    {responses[1] ? <Typography variant={"caption"}>Compared to {selfRating1} last quarter</Typography>: ''}
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant={"body1"}>Overall Joy</Typography>
                                    <Typography variant={"h6"}>{joyRatingPercentage0}%{responses[1] ? renderDelta(joyRatingPercentage0, joyRatingPercentage1): ''}</Typography>
                                    {responses[1] ? <Typography variant={"caption"}>Compared to {joyRatingPercentage1} last quarter</Typography>: ''}
                                </Grid>
                                <Grid item xs={12} style={{textAlign: 'center'}}>
                                    <Typography variant={"body1"}>Skill development interests</Typography>
                                </Grid>
                                {renderIsLearnSkills(responses[0])}
                            </Grid>
                        </Paper>
                    </Grid>
                )
            })
        }
        return cardJsx
    }

    return (
        <Grid container spacing={4}>
            {renderTeamMembers(responses)}
        </Grid>
    )
}
