import React, {useEffect, useState} from 'react';
import { Radar } from 'react-chartjs-2';
import {getRubric} from "../../services/Util";

function SelfRatingOverviewRadarChart({responses, survey}){

    const [data, setData] = useState({})

    useEffect(() => {

        let scores = {}
        let categoryCounts = {}

        if (survey && survey.skillGroups && responses && Array.isArray(responses)) {
            Object.entries(survey.skillGroups).forEach(([skillGroupId, skillGroupSkills]) => {
                Object.entries(skillGroupSkills).forEach(([skillId, skill]) => {

                    responses.forEach(response => {
                        if (response.skillGroups && response.skillGroups[skillGroupId] && response.skillGroups[skillGroupId][skillId]) {
                            const responseData = response.skillGroups[skillGroupId][skillId]
                            if (scores[skill.category] === undefined) {
                                scores[skill.category] = responseData.selfRating ? responseData.selfRating : 0
                                categoryCounts[skill.category] = 1
                            } else {
                                scores[skill.category] += responseData.selfRating ? responseData.selfRating : 0
                                categoryCounts[skill.category] += 1
                            }
                        }
                    })

                })
            })
        }


        Object.entries(categoryCounts).forEach(([category, count]) => {
            scores[category] = scores[category] / count
        })

        const dataLocal = {
            labels: Object.keys(scores),
            datasets: [
                {
                    label: 'Self Rating',
                    data: Object.values(scores),
                    backgroundColor: 'rgba(91,16,255,0.2)',
                    borderColor: 'rgb(91,16,255)',
                    borderWidth: 1,
                },
            ],
        }

        setData(dataLocal)

    }, [responses, survey])

    const options = {
        scale: {
            ticks: { beginAtZero: true},
            r: {
                angleLines: {
                    display: false
                },
                suggestedMin: 0,
                suggestedMax: getRubric(survey).length,
            }
        },
        plugins: {
            title: {
                display: false,
                text: 'Team\'s Self Rating',
            },
            legend: {
                display: false
            }
        },
        aspectRatio: 1.5
    };

    return (
        <React.Fragment>
            <Radar data={data} options={options} />
        </React.Fragment>
    )
}

export default SelfRatingOverviewRadarChart;
